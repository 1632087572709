<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)">

        <b-row>

          <b-col cols="12" md="12" lg="12">
            <div class="d-flex">
              <feather-icon icon="LayersIcon" size="16"/>
              <h5 class="mb-0 mt-0 ml-50">Genel Bilgiler</h5>
            </div>

            <b-row class="mt-1 mb-1">

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Kod" rules="required">
                  <b-form-group label="* Kod">
                    <b-form-input trim placeholder="Kod" v-model="dataInfo.code" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="İsim" rules="required">
                  <b-form-group label="* İsim">
                    <b-form-input trim placeholder="İsim" v-model="dataInfo.name" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Şirket" rules="required">
                  <b-form-group label="* Şirket" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.corporationId" :options="corporationOptions" :reduce="val => val.value" :clearable="false" v-on:input="getCorporation"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Organizasyon Tipi" rules="required">
                  <b-form-group label="* Organizasyon Tipi" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.organizationTypeId" :options="organizationTypeOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Üst Organizasyon">
                  <b-form-group label="Üst Organizasyon" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.parentOrganizationId" :options="parentOrganizationOptions" :reduce="val => val.value" :clearable="true"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Yönetici Pozisyonu">
                  <b-form-group label="Yönetici Pozisyonu" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.managerPositionId" :options="managerPositionOptions" :reduce="val => val.value" :clearable="true"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

            </b-row>

            <div class="d-flex">
              <feather-icon icon="WatchIcon" size="16"/>
              <h5 class="mb-0 ml-50">Çalışma Bilgileri</h5>
            </div>

            <b-row class="mt-1  mb-3">
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Başlangıç Saati" rules="required">
                  <b-form-group label="* Başlangıç Saati" :state="getValidationState(validationContext)">
                    <b-form-timepicker v-model="dataInfo.workingStartTime" class="mb-2"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Bitiş Saati" rules="required">
                  <b-form-group label="* Bitiş Saati" :state="getValidationState(validationContext)">
                    <b-form-timepicker v-model="dataInfo.workingEndTime" local="tr" class="mb-2"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <b-form-checkbox class="custom-control-success mr-5" v-model="dataInfo.monday" switch inline>Pazartesi</b-form-checkbox>
                <b-form-checkbox class="custom-control-success mr-5" v-model="dataInfo.tuesday" switch inline>Salı</b-form-checkbox>
                <b-form-checkbox class="custom-control-success mr-5" v-model="dataInfo.wednesday" switch inline>Çarşamba</b-form-checkbox>
                <b-form-checkbox class="custom-control-success mr-5" v-model="dataInfo.thursday" switch inline>Perşembe</b-form-checkbox>
                <b-form-checkbox class="custom-control-success mr-5" v-model="dataInfo.friday" switch inline>Cuma</b-form-checkbox>
                <b-form-checkbox class="custom-control-success mr-5" v-model="dataInfo.saturday" switch inline>Cumartesi</b-form-checkbox>
                <b-form-checkbox class="custom-control-success mr-5" v-model="dataInfo.sunday" switch inline>Pazar</b-form-checkbox>
              </b-col>
            </b-row>

            <div class="d-flex">
              <feather-icon icon="InfoIcon" size="16"/>
              <h5 class="mb-0 ml-50">Diğer Bilgiler</h5>
            </div>

            <b-row class="mt-1">

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Erişim Bilgileri">
                  <b-form-group label="Erişim Bilgileri" description="Bu erişim bilgilerine sahip kullanıcılar tarafından görüntülenecektir." :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.accessDefinitionList" :options="accessDefinitionOptions" :reduce="val => val.value" :clearable="true" multiple="multiple"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Durum" rules="required">
                  <b-form-group label="* Durum" label-for="isActive" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Açıklama">
                  <b-form-group label="Açıklama">
                    <b-form-textarea trim placeholder="Açıklama" v-model="dataInfo.description" :state="getValidationState(validationContext)" rows="2"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

            </b-row>

            <div class="d-flex">
              <feather-icon icon="SlidersIcon" size="16"/>
              <h5 class="mb-0 ml-50">Onay Süreci Bilgileri</h5>
            </div>

            <div class="mt-1">
              <b-row class="border-bottom border-top p-1">
                <b-col cols="12" md="4" lg="4">
                  <b-form-group label="Form Tipi">
                    <v-select v-model="workflowInfo.formTypeId" :options="formTypeOptions" :reduce="val => val.value" :clearable="false"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4" lg="4">
                  <b-form-group label="Onay Süreci">
                    <v-select v-model="workflowInfo.workflowId" :options="workflowOptions" :reduce="val => val.value" :clearable="false"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4" lg="4" class="mt-1 d-flex justify-content-start align-items-center">
                  <b-button variant="primary" @click="addWorkflow">
                    <feather-icon icon="PlusSquareIcon" size="16"/>
                    <span class="align-middle" role="button"> Ekle</span>
                  </b-button>
                </b-col>
              </b-row>

              <div class="pt-1">

                <div v-for="(workflow, index) in dataInfo.workflowList" :key="index" class="d-flex justify-content-between align-items-center border-bottom py-1">
                  <div class="d-flex justify-content-between align-items-center">
                    <b-avatar size="32" class="mr-50" :text="(index+1).toString()" :variant="`light-primary`"/>
                    <div class="user-page-info">
                      <h6 class="mb-0">
                        {{ workflow.formTypeName }}
                      </h6>
                      <small class="text-muted">{{ workflow.workflowName }}</small>
                    </div>
                  </div>
                  <div>
                    <b-button variant="danger" class="btn-icon ml-1" @click="removeWorkflow(workflow)">
                      <feather-icon icon="XIcon"/>
                      Çıkar
                    </b-button>
                  </div>
                </div>

              </div>

            </div>

          </b-col>

        </b-row>

        <action-buttons :back-route="'organization-organization-list'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BTabs, BTab, BFormTextarea, BAvatar, BFormCheckbox, BFormTimepicker, BButton
} from 'bootstrap-vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import {onUnmounted, ref} from '@vue/composition-api'
import {useToast} from 'vue-toastification/composition'
import {toastMessage} from "@core/utils/utils";
import router from '@/router'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import {avatarText, getApiFile, statusOptions, yesNoOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/organization/organization/store"
import accessModule from "@/views/definition/access/store"
import corporationModule from "@/views/organization/corporation/store"
import positionModule from "@/views/organization/position/store"
import definitionModule from "@/views/system/definition/store"
import workflowModule from "@/views/definition/workflow/store"
import Vue from "vue";

export default {
  components: {
    BButton,
    BFormTimepicker,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BTabs,
    BTab,
    BFormTextarea,
    BAvatar,

    ActionButtons,
    Overlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_ACCESS_MODULE_NAME = 'store-access'
    const STORE_CORPORATION_MODULE_NAME = 'store-corporation'
    const STORE_POSITION_MODULE_NAME = 'store-position'
    const STORE_DEFINITION_MODULE_NAME = 'store-definition'
    const STORE_WORKFLOW_MODULE_NAME = 'store-workflow'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_ACCESS_MODULE_NAME, accessModule)
      store.registerModule(STORE_CORPORATION_MODULE_NAME, corporationModule)
      store.registerModule(STORE_POSITION_MODULE_NAME, positionModule)
      store.registerModule(STORE_DEFINITION_MODULE_NAME, definitionModule)
      store.registerModule(STORE_WORKFLOW_MODULE_NAME, workflowModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_ACCESS_MODULE_NAME)
        store.unregisterModule(STORE_CORPORATION_MODULE_NAME)
        store.unregisterModule(STORE_POSITION_MODULE_NAME)
        store.unregisterModule(STORE_DEFINITION_MODULE_NAME)
        store.unregisterModule(STORE_WORKFLOW_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)
    const dataInfo = ref({
      id: 0,
      code: '',
      name: '',
      corporationId: null,
      parentOrganizationId: null,
      managerPositionId: null,
      organizationTypeId: null,
      description: null,
      isActive: true,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: false,
      workingStartTime: null,
      workingEndTime: null,
      accessDefinitionList: [],
      workflowList: [],
    })

    const workflowInfo = ref({
      workflowId: null,
      workflowName: '',
      formTypeId: null,
      formTypeName: '',
    })

    const managerPositionOptions = ref([])
    const parentOrganizationOptions = ref([])
    const corporationOptions = ref([])
    const organizationTypeOptions = ref([])
    const accessDefinitionOptions = ref([])
    const formTypeOptions = ref([])
    const workflowOptions = ref([])

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'organization-organization-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const getCorporation = (corporationId) => {
      if (router.currentRoute.params.id === undefined) {
        busy.value = true
        store.dispatch('store-corporation/fetchItem', {id: corporationId}).then(response => {
          dataInfo.value.monday = response.data.data.monday
          dataInfo.value.tuesday = response.data.data.tuesday
          dataInfo.value.wednesday = response.data.data.wednesday
          dataInfo.value.thursday = response.data.data.thursday
          dataInfo.value.friday = response.data.data.friday
          dataInfo.value.saturday = response.data.data.saturday
          dataInfo.value.sunday = response.data.data.sunday
          dataInfo.value.workingStartTime = response.data.data.workingStartTime.split('T')[1].replace('.0000000', '')
          dataInfo.value.workingEndTime = response.data.data.workingEndTime.split('T')[1].replace('.0000000', '')
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })
      }
    }

    busy.value = true
    store.dispatch('store-corporation/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          corporationOptions.value.push({label: value.shortTitle, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-workflow/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          workflowOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-position/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          managerPositionOptions.value.push({label: value.code + ' - ' + value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-access/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          accessDefinitionOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive && value.id !== router.currentRoute.params.id) {
          parentOrganizationOptions.value.push({label: value.code + ' - ' + value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'ORGANIZATION_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        organizationTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.organizationTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'FORM_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        formTypeOptions.value.push({label: value.name, value: value.key})
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
        if (dataInfo.value.workingStartTime !== null) {
          dataInfo.value.workingStartTime = dataInfo.value.workingStartTime.split('T')[1].replace('.0000000', '')
          dataInfo.value.workingEndTime = dataInfo.value.workingEndTime.split('T')[1].replace('.0000000', '')
        }
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'organization-organization-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const addWorkflow = () => {
      let workflowName = '';
      workflowOptions.value.forEach((value, index) => {
        if (value.value === workflowInfo.value.workflowId) {
          workflowName = value.label
        }
      });

      let formTypeName = '';
      formTypeOptions.value.forEach((value, index) => {
        if (value.value === workflowInfo.value.formTypeId) {
          formTypeName = value.label
        }
      });

      let isAppendItem = true

      dataInfo.value.workflowList.forEach((value, index) => {
        if (value.formTypeId === workflowInfo.value.formTypeId && value.workflowId === workflowInfo.value.workflowId) {
          isAppendItem = false
        }
      });

      if (isAppendItem) {
        dataInfo.value.workflowList.push({
          formTypeId: workflowInfo.value.formTypeId,
          formTypeName: formTypeName,
          workflowId: workflowInfo.value.workflowId,
          workflowName: workflowName,
        })
      }

      workflowInfo.value = {
        workflowId: null,
        workflowName: '',
        formTypeId: null,
        formTypeName: '',
      };

    }

    const removeWorkflow = (workflowData) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Kayıt çıkarılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Çıkar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          dataInfo.value.workflowList.splice(dataInfo.value.workflowList.indexOf(workflowData), 1);
        }
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      busy,
      dataInfo,
      refFormObserver,
      managerPositionOptions,
      parentOrganizationOptions,
      corporationOptions,
      organizationTypeOptions,
      accessDefinitionOptions,
      workflowInfo,
      formTypeOptions,
      workflowOptions,

      statusOptions,
      yesNoOptions,

      onSubmit,
      getValidationState,
      resetForm,
      getApiFile,
      avatarText,
      getCorporation,
      addWorkflow,
      removeWorkflow,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>